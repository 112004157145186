import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { format } from "date-fns";
import {
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { testUserFilterQuery } from "../../app/services/AdminService";

export default function ModalNewCampaigns(props) {
  const [bonusAmountType, setBonusAmountType] = useState("ABSOLUTE");
  const [dateFrom, setDataFrom] = useState(new Date());
  const [dateTo, setDataTo] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [minDepositValueToActivate, setMinDepositValueToActivate] = useState(0);
  const [allowMultipleUsages, setAllowMultipleUsages] = useState(false);
  const [expireInNDays, setExpireInNDays] = useState(0);
  const [campaignType, setCampaignType] = useState("BONUS_FTD");
  const [maxAmount, setMaxAmount] = useState(0);
  const [volumeMultiplicationFactor, setVolumeMultiplicationFactor] = useState(0);
  const [partnerReferralIdToShare, setPartnerReferralIdToShare] = useState("");
  const [mongoQuery, setMongoQuery] = useState("");
  const [testQueryResult, setTestQueryResult] = useState(null);
  const [isQueryLoading, setIsQueryLoading] = useState(false);

  const toogleClose = () => {
    setCampaignType("BONUS_FTD");
    setBonusAmountType("ABSOLUTE");
    setDataFrom(new Date());
    setDataTo(new Date());
    setAmount(0);
    setAllowMultipleUsages(false);
    setMinDepositValueToActivate(0);
    setMaxAmount(0);
    setExpireInNDays(0);
    setCampaignType("BONUS_FTD");
    setMaxAmount(0);
    setVolumeMultiplicationFactor(0);
    setPartnerReferralIdToShare("");
    setMongoQuery("");
    setTestQueryResult(null);
    props.onClose();
  };
  const handleAddUser = () => {
    props.onAddCampaign({
      campaignType,
      bonusAmountType,
      dateFrom,
      dateTo,
      amount,
      minDepositValueToActivate,
      maxAmount,
      allowMultipleUsages,
      expireInNDays,
      volumeMultiplicationFactor,
      partnerReferralIdToShare,
      mongoQuery,
    });
    toogleClose();
  };

  const disabled = useMemo(() => {
    return (
      bonusAmountType === "" ||
      dateFrom === "" ||
      dateTo === "" ||
      amount === 0 ||
      expireInNDays === 0
    );
  }, [bonusAmountType, dateFrom, dateTo, amount, expireInNDays]);

  const handleTestQuery = async () => {
    setIsQueryLoading(true);
    try {
      const response = await testUserFilterQuery(mongoQuery);
      setTestQueryResult(`A query retornou ${response.data} usuários.`);
    } catch (error) {
      setTestQueryResult("Erro ao testar a query: " + error.message);
    } finally {
      setIsQueryLoading(false);
    }
  };

  return (
    <Modal show={props.open} onHide={toogleClose} hideBackdrop>
      <Modal.Header closeButton>
        <Modal.Title>Nova campanha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack
          direction={"column"}
          justifyContent="space-between"
          spacing={4}
          sx={{ mb: 2 }}
        >
          <FormControl fullWidth>
            <InputLabel id="select-campaing-type">Tipo de campanha</InputLabel>
            <Select
              labelId="select-campaing-type"
              id="select-campaing-type"
              name="campaing-type"
              onChange={(env) => {
                if (env.target.value === "FTD") {
                  setAllowMultipleUsages(false);
                }
                setCampaignType(env.target.value);
              }}
              value={campaignType}
            >
              <MenuItem value={"BONUS_FTD"}>FTD</MenuItem>
              <MenuItem value={"BONUS_DEPOSIT"}>Depósito</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 120 }}>
            <MobileDatePicker
              label="De"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={dateFrom}
              onChange={(e) => setDataFrom(format(e, "dd/MM/yyyy"))}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 120, mt: 2 }}>
            <MobileDatePicker
              label="Até"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={dateTo}
              onChange={(e) => setDataTo(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select-type">Tipo de cálculo</InputLabel>
            <Select
              labelId="select-type"
              id="select-type"
              name="bonusAmountType"
              onChange={(env) => {
                setBonusAmountType(env.target.value);
              }}
              value={bonusAmountType}
            >
              <MenuItem value={"ABSOLUTE"}>Absoluto</MenuItem>
              <MenuItem value={"PERCENTUAL"}>Percentual</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="amount"
            name="amount"
            label="Valor"
            fullWidth
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
          />
          <TextField
            id="maxAmount"
            name="maxAmount"
            label="Valor máximo"
            fullWidth
            type="number"
            value={maxAmount}
            onChange={(e) => setMaxAmount(e.target.value)}
            variant="outlined"
          />
          <TextField
            id="minDepositValueToActivate"
            name="minDepositValueToActivate"
            label="Valor mínimo para ativar"
            fullWidth
            type="number"
            value={minDepositValueToActivate}
            onChange={(e) => setMinDepositValueToActivate(e.target.value)}
            variant="outlined"
          />
          {campaignType === "BONUS_DEPOSIT" && (
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              sx={{ color: "#A5AAAD" }}
              label="Permitir múltiplos usos"
              checked={allowMultipleUsages}
              name="allowMultipleUsages"
              onChange={() => setAllowMultipleUsages(!allowMultipleUsages)}
            />
          )}
          <TextField
            id="expireInNDays"
            name="expireInNDays"
            label="Expirar em N dias"
            fullWidth
            type="number"
            value={expireInNDays}
            onChange={(e) => setExpireInNDays(e.target.value)}
            variant="outlined"
          />
          <TextField
            id="volumeMultiplicationFactor"
            name="volumeMultiplicationFactor"
            label="Fator de multiplicação de volume"
            fullWidth
            type="number"
            value={volumeMultiplicationFactor}
            onChange={(e) => setVolumeMultiplicationFactor(e.target.value)}
            variant="outlined"
          />
          <TextField
            id="partnerReferralIdToShare"
            name="partnerReferralIdToShare"
            label="Partner associado à campanha."
            fullWidth
            value={partnerReferralIdToShare}
            onChange={(e) => setPartnerReferralIdToShare(e.target.value)}
            variant="outlined"
          />
          <TextField
            id="mongoQuery"
            name="mongoQuery"
            label="Mongo Query"
            fullWidth
            multiline
            rows={4}
            value={mongoQuery}
            onChange={(e) => setMongoQuery(e.target.value)}
            variant="outlined"
          />

          <Button
            variant="contained"
            onClick={handleTestQuery}
            disabled={isQueryLoading || !mongoQuery}
          >
            {isQueryLoading ? "Testando..." : "Testar Query"}
          </Button>

          {testQueryResult && (
            <Typography
              variant="body2"
              color={testQueryResult.startsWith("Erro") ? "error" : "success"}
              sx={{ mt: 1 }}
            >
              {testQueryResult}
            </Typography>
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button sx={{ mr: 1 }} variant="contained" onClick={toogleClose}>
          Cancelar
        </Button>
        <Button
          disabled={disabled}
          color="warning"
          variant="contained"
          onClick={handleAddUser}
        >
          Criar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalNewCampaigns.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAddCampaign: PropTypes.func,
};